import React from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import uniqueId from 'react-html-id';
import Page from '../components/Page';
import Grid from '../components/Grid';
import LargeHeader from '../components/LargeHeader';
import Footer from '../components/Footer';
import './pages.scss';
import pattern from './images/pattern.png';

class BlogIndex extends React.Component {
  constructor(props) {
    super(props);

    uniqueId.enableUniqueIds(this);
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const posts = get(this, 'props.data.allMarkdownRemark.edges');
    let even = true;

    const PatternComponent = (props) => {
      let position = props.position === 'left' ? 'is-left' : 'is-right';
      position = `c-posts__item-pattern ${position}`;
      return (
        <div className={position} key={props.id}>
          <img src={pattern} className="c-posts__item-img" alt="" key={props.id} />
        </div>
      );
    };

    const ArticleComponent = (props) => {
      let position = props.position === 'left' ? 'is-left' : 'is-right';
      position = `c-posts__item-content ${position}`;
      return (
        <Link to={props.data.fields.slug} className={position} key={props.uniqueId.nextUniqueId()}>
          <article key={props.uniqueId.nextUniqueId()}>
            <h6 key={props.uniqueId.nextUniqueId()}>{props.title}</h6>
            <small key={props.uniqueId.nextUniqueId()}>{props.data.frontmatter.date}</small>
            <p
              key={props.uniqueId.nextUniqueId()}
              dangerouslySetInnerHTML={{ __html: props.data.excerpt }}
            />
          </article>
        </Link>
      );
    };

    return (
      <Page title={siteTitle}>
        <Grid>
          <div className="c-index">
            <LargeHeader />
            <div className="c-posts">
              {posts.map(({ node }) => {
                even = !even;
                const title = get(node, 'frontmatter.title') || node.fields.slug;
                if (even === false) {
                  return (
                    <section className="c-posts__item" key={this.nextUniqueId()}>
                      <ArticleComponent position="left" uniqueId={this} title={title} data={node} />
                      <PatternComponent position="right" id={this.nextUniqueId()} />
                    </section>
                  );
                }

                return (
                  <section className="c-posts__item" key={this.nextUniqueId()}>
                    <PatternComponent position="left" id={this.nextUniqueId()} />
                    <ArticleComponent position="right" uniqueId={this} title={title} data={node} />
                  </section>
                );
              })}
            </div>
          </div>
          <Footer />
        </Grid>
      </Page>
    );
  }
}

export default BlogIndex;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { published: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 280)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
          }
        }
      }
    }
  }
`;
