import React from 'react';
import './LargeHeader.scss';
import patternDark from './images/pattern-dark-right.png';
import logo from './images/104vh-dark-bg.png';
import logoSmall from './images/96vh-dark-bg.png';
import { Link } from 'gatsby';

class LargeHeader extends React.Component {
  render() {
    return (
      <header className="c-header-grid">
        <div className="c-small-header--dark">
          <Link to={'/'}>
            <img src={logoSmall} className="c-small-header__logo--dark" alt="Design & Pizza logo" />
          </Link>
        </div>
        <section className="c-header">
          <span role="img" aria-label="hand waving">
            👋
          </span>{' '}
          I don’t think we have met each other.
          <br />
          <br />
          My name is <a href="https://christopherandersson.com">Christopher Andersson</a> and I have
          been designing and building products on the web since 1999.
          <br />
          <br />
          Nice to meet you{' '}
          <span role="img" aria-label="smiling face">
            😄
          </span>
          <br />
          <br />
          <a href="https://designand.pizza">Design Pizza</a> is a space where I share deep dives
          into various web technologies in an attempt to be a better product designer.
          <br />
          <br />I suggest getting started here{' '}
          <span>
            <span role="img" aria-label="pointing at the link">
              👉
            </span>{' '}
            <Link to="/wtfjs">WTF JavaScript</Link>
          </span>
        </section>
        <section className="c-header__logo-container">
          <img src={logo} className="c-header__logo" alt="Design & Pizza logo" />
        </section>
        <img src={patternDark} className="c-header__pattern" alt="pattern of pizza slices" />
      </header>
    );
  }
}

export default LargeHeader;
